<template>
  <v-container>
    <v-layout text-xs-center wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title>User Settings</v-card-title>
          <v-card-text>
            <v-switch v-model="darkMode" :label="`Dark mode: ${darkMode? 'Enabled' : 'Disabled'}`"></v-switch>
            <v-switch v-model="miniMenu" :label="`Mini menu: ${miniMenu? 'Enabled' : 'Disabled'}`"></v-switch>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { computed } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const darkMode = computed({
      get: () => { return ('settings' in root.$store.state.user && 'darkMode' in root.$store.state.user.settings) ? root.$store.state.user.settings.darkMode : false },
      set: value => { root.$store.dispatch('updateUserSetting', { field: 'darkMode', value }) }
    })
    const miniMenu = computed({
      get: () => { return ('settings' in root.$store.state.user && 'miniMenu' in root.$store.state.user.settings) ? root.$store.state.user.settings.miniMenu : false },
      set: value => { root.$store.dispatch('updateUserSetting', { field: 'miniMenu', value }) }
    })
    const settings = computed({
      get: () => { return root.$store.state.user.settings },
      set: value => {
        for (let field in value) {
          root.$store.dispatch('updateUserSetting', { field, value })
        }
      }
    })

    return {
      darkMode,
      miniMenu,
      settings
    }
  }
}
</script>
